.spinner-wrapper {
  display: block;
  text-align: center;
  margin-top:1rem;
}

.spinner {
  height:35px;
  width:35px;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}

.spinner span {
  border-radius: 40px;
  position:absolute;
  top:0;
  bottom:0;
  right:0;
  left:0;
  border:1px solid #0984e3;
  opacity:0;
  -webkit-animation: scale 3s ease-out infinite;
}
.label {
  font-size:1rem;
  font-weight:300;
  display:inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left:7px;
}

.label:after {
  content:'...'
}

.spinner span:nth-child(2) {
  -webkit-animation-delay:2s;
}

.spinner span:nth-child(3) {
  -webkit-animation-delay:1s;
}

@keyframes scale {
  0% {opacity:0;-webkit-transform:scale(0);}
  70% {opacity:1;}
  100% {opacity:0;-webkit-transform:scale(1);}
}