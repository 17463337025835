@import 'basics';
@import 'reset';

html{
  font-family: $font-muli;
  background:$bg-color;
}

body {
  margin: 0;
  padding:0;
  font-family: $font-muli;
  line-height: 1.5;
  font-size:14px;
  font-feature-settings: 'tnum', "tnum";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

* {
  box-sizing: border-box;
}

h1{
  font-size: 32px;
  line-height:1.5;
  font-weight: 900;
}

h3{
  font-size: 18px;
  line-height:1.5;
  font-weight: 700;
}