@import 'basics';

.root{
  width:100%;
  display: flex;
  flex-direction: column;
}

.header{
  height:76px;
  background:$bg-dark-light;
  border-bottom:$border-dark 1px solid;
  padding:0 20px;
  align-items: center;
  display: flex;
}

.frame{
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  max-width:1024px;
  width:100%;
  margin:0 auto;
}