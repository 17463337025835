$greenish-turquoise: #00E9A0;
$greenish-turquoise-2: #00BF82;
$light-purple: #A135FF;
$light-purple-2: #B363F9;
$light-purple-3: #f2eaff;
$green-blue: #00d894;
$black: #000000;
$white: #ffffff;
$white-two: #dedede;
$grey: #999999;
$black-5: rgba(0, 0, 0, 0.05);
$black-7: rgba(0, 0, 0, 0.07);

$bg: #393952;
$bg-color:#131320;
$bg-darkerer:#1b1b2c;
$bg-darker:#212136;
$bg-darkest: #191929;
$bg-dark: #2a2a45;
$bg-dark-light: #25233f;
$bg-dark-lighter: #1d1d33;
$border-dark: #43415e;

$success: #1eb007;
$success-bright: #22c908;
$danger: #ec002d;
$danger-bright: #ff0736;
$primary:#0072f5;
$primary-bright:#0f7fff;

$font-muli: 'Muli', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
