@import 'basics';

.signin{
  display: flex;
  height: 100vh;
  width: 100vw;
  
  .logo{
    margin-bottom:0px;
  }

  h1{
    color:white;
    text-align: center;
    font-size:1.4rem;
    font-weight: 500;
    margin:-10px 0 15px 0;
  }
  
  &Box{
    background:white;
    margin:auto;
    display: flex;
    flex-direction: column;
    width:500px;
    background:$bg-dark-light;
    border-radius: 10px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.4);
    padding:3rem;
    align-items: center;
  }
}

.syncImage{
  width:300px
}

.syncText{
  font-size:15px;
  text-align: center;
  margin-bottom:40px;
}