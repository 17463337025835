@import 'basics';

.root{
  td{
    height:69px; 
  }

  .actionsCell{
    width: 1%;
    white-space: nowrap;
    text-align: right;

    .error{
      font-size:10px;
    }
  }
  
  .syncStatus{
    span{
      color: $success;
      font-size:13px;
    }
  }

  .syncTimes{
    font-size:11px;
    margin-top:3px;
  }

}