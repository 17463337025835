@import 'basics';

.root{
  border-radius: 6px;
  height:38px;
  font-size:14px;
  color: $white;
  background:transparent;
  padding:1px 12px;
  transition: background-color 0.3s ease-in-out;
  border:1px solid $white;

  &:hover:enabled{
    background: rgba($grey, 0.4)
  }

  &:disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.danger{
  background: $danger;
  border: 1px solid $danger-bright;

  &:hover:enabled{
    background: rgba($danger, 0.7)
  }
}

.primary{
  background: $primary;
  border: 1px solid $primary-bright;

  &:hover:enabled{
    background: rgba($primary, 0.7)
  }
}